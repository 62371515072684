import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container } from "react-bootstrap"

import Layout from "../../../components/layout"
import PostsList from "../../../components/postlist"
import Slider from "../../../components/slider"

const CategoryTemplate = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      tagok: allMarkdownRemark(
        limit: 1000
        filter: { fields: { category: { eq: "tagok" } } }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        totalCount
        edges {
          node {
            fields {
              slug
              category
            }
            excerpt
            timeToRead
            frontmatter {
              title
              description
              date
            }
          }
        }
      }
    }
  `)

  const title = "Csapatunk"
  const subtitle = "Az AURA emberei"

  return (
    <Layout location={location} title={title} description={subtitle}>

      <Slider title={title} subtitle={subtitle}/>

      <Container fluid>
        <PostsList postEdges={data.tagok.edges}/>
      </Container>
    </Layout>
  )
}

export default CategoryTemplate
